<template>
  <div>
    <images></images>
  </div>
</template>

<script>
  import Images from '../../components/Images/Images';

  export default {
    name: 'Images',
    components: {
      images: Images
    },
    data() {
      return {

      };
    }
  };
</script>
